import { render, staticRenderFns } from "./Announcements.vue?vue&type=template&id=a5bc3f42&"
import script from "./Announcements.vue?vue&type=script&lang=js&"
export * from "./Announcements.vue?vue&type=script&lang=js&"
import style0 from "./Announcements.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VDivider,VIcon})
