<template>
  <div class="announcements-item" :key="key">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/announcements">Announcements</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.message_title }}</span>
    </div>
    <v-card class="mt-3" width="100%" outlined>
      <div class="pl-lg-9 pa-sm-7 pa-3">
        <div class="d-flex flex-md-row flex-column">
          <v-card flat tile class="me-md-10 width100" max-width="400">
            <div class="font-weight-bold">Message title</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ data.message_title }}</div>
            </v-card>
            <div class="font-weight-bold mt-4">Message text</div>
            <v-card flat min-height="46" class="gray lighten-4 px-3 py-2 d-flex align-center mt-2">
              <div class="text--text">{{ data.message_text }}</div>
            </v-card>
            <div class="font-weight-bold mt-4">Sending option</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-capitalize text-truncate">{{ data.sending_type }}</div>
            </v-card>
            <div class="font-weight-bold mt-4">Dates</div>
            <v-card flat height="46" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">
                <v-icon color="text lighten-1">mdi-calendar-range</v-icon>
                {{
                  data.scheduled_at
                    ? new Date(data.scheduled_at).toLocaleString('en-GB', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                    : new Date(data.submitted_at).toLocaleString('en-GB', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                }}
              </div>
            </v-card>
          </v-card>
          <v-card flat tile class="ms-md-10 width100 mt-md-0 mt-2" max-width="542">
            <div class="d-flex justify-space-between my-5">
              <v-card flat class="primary darken-1 width100 white--text py-3 px-5" max-width="170" height="73">
                <div class="text-caption">Sent</div>
                <div class="text-h6">{{ data.messages_sent || 0 }}</div>
              </v-card>
              <v-card flat class="primary lighten-2 width100 white--text py-3 px-5 mx-4" max-width="170" height="73">
                <div class="text-caption">Opened</div>
                <div class="text-h6">{{ data.messages_opened || 0 }}</div>
              </v-card>
              <v-card outlined class="width100 py-3 px-5" max-width="170" height="73" style="box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12)">
                <div class="text-caption disabled--text text-truncate overflow-y-hidden">Conversion rate</div>
                <div class="text-h6">{{ data.conversion_rate || 0 }}%</div>
              </v-card>
            </div>
            <v-chartist type="Bar" :data="chartData" :options="chartOptions"></v-chartist>
            <v-card flat class="mt-n7 ml-14 mr-2 d-flex justify-space-around width100" max-width="477">
              <div class="d-flex align-center">
                <v-card flat width="12" height="12" color="primary darken-1" rounded="circle"></v-card>
                <div class="ml-1 text-caption">Sent</div>
              </div>
              <div class="d-flex align-center">
                <v-card flat width="12" height="12" color="primary lighten-1" rounded="circle"></v-card>
                <div class="ml-1 text-caption">Opened</div>
              </div>
            </v-card>
          </v-card>
        </div>
      </div>
      <div v-if="data.status == 'scheduled'">
        <v-divider></v-divider>
        <div class="px-sm-10 px-3 py-3">
          <v-btn @click="cancel" depressed outlined color="gray" width="126" height="30">
            <span class="text-body-2 error--text">Cancel</span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueChartist from 'v-chartist';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    'v-chartist': VueChartist,
  },
  data() {
    return {
      key: 1,
      chartData: {
        labels: ['Sent', 'Opened'],
        series: [],
      },
      chartOptions: {
        height: '380px',
        fullWidth: true,
        distributeSeries: true,
        axisX: {
          showLabel: false,
        },
      },
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getAnnouncementsItem');
  },
  methods: {
    async cancel() {
      await this.$store.dispatch('cancelAnnouncementsItem', this.data.id);
    },
  },
  watch: {
    data(val) {
      this.chartData.series = [];
      if (val) {
        this.chartData.series.push(+this.data.messages_sent);
        this.chartData.series.push(+this.data.messages_opened);
        ++this.key;
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.announcements;
    },
  },
  destroyed() {
    this.$store.dispatch('setAnnouncementsItem');
  },
};
</script>

<style lang="scss">
.announcements-item {
  .ct-series line {
    stroke-width: 150px;
  }
  .ct-series-a .ct-point,
  .ct-series-a .ct-bar {
    stroke: var(--v-primary-darken1);
  }
  .ct-series-b .ct-point,
  .ct-series-b .ct-bar {
    stroke: var(--v-primary-lighten1);
  }
}
</style>
